.modal-vedio {
  .modal-xl {
    --bs-modal-width: 1291px;
  }
  .modal-content {
    background-color: var(--chalk);
    .modal-header {
      display: flex;
      padding: 24px;
      justify-content: space-between;
      align-items: center;
      border: none;
      .modal-title {
        font-family: Karla;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 41px; /* 128.125% */
        letter-spacing: -1.28px;
      }
    }
    .modal-body {
      padding: 0px 24px 24px;
      .form-control-wrapper {
        max-width: 450px;
        overflow: hidden;
        position: relative;
        .form-control {
          padding: 8px 16px !important;
          border-radius: 20px;
          border: 1px solid var(--gray-500);
          background: #fff;
        }
        &::after {
          background-image: url(http://localhost:3000/static/media/search.1bf5c4eda80320c4eff2.svg);
          content: "";
          position: absolute;
          top: 10px;
          right: 16px;
          width: 30px;
          height: 30px;
          background-repeat: no-repeat;
        }
      }
      .cards_wrapper {
        margin-top: 24px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        gap: 24px;
        .cardCustom {
          max-width: 394px;
          width: 100%;
          max-height: 300px;
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          overflow: hidden;

          .vedioContainer {
            padding: 20px 20px 0px 20px;
            border-radius: 8px 8px 0px 0px;
            background: #fcf9f0;
            .vedioWrapper {
              border-radius: 4px 4px 0px 0px;
              border-top: 1px solid var(--gray-500);
              border-right: 1px solid var(--gray-500);
              border-left: 1px solid var(--gray-500);
              .vedioControl {
                max-height: 195px;
              }
            }
          }
          .card_footer {
            // position: absolute;
            // bottom: 0px;
            // left: 0px;
            background-color: white;
            width: 100%;
            max-width: 394px;
            display: flex;
            display: flex;
            padding: 20px;
            align-items: flex-start;
            justify-content: space-between;
            gap: 40px;
            border-radius: 0px 0px 8px 8px;
            background: #fff;
            height: 100%;
            max-height: 84px;
            .headingBox {
              flex-direction: column;
              display: flex;
              .vedioName {
                font-family: Karla;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
                letter-spacing: -0.64px;
                margin: 0px;
              }
              .vedioDesc {
                color: #74787f;
                /* Paragraph/Medium-sm */
                margin: 0px;
                font-family: Karla;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
                letter-spacing: -0.56px;
              }
            }
            .playBtn {
              border-radius: 30px;
              border: 1px solid var(--gray-800, #454b54);
              display: flex;
              width: 24px;
              height: 24px;
              padding: 6px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }
}
