.modal-custom {
  font-family: Karla;
  .modal-dialog {
    max-width: 673px;
  }
  .modal-content {
    border-radius: 20px;
    background: var(--white, #fff);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
      0px 19px 41px 0px rgba(0, 0, 0, 0.1),
      0px 74px 74px 0px rgba(0, 0, 0, 0.09),
      0px 167px 100px 0px rgba(0, 0, 0, 0.05),
      0px 296px 119px 0px rgba(0, 0, 0, 0.01),
      0px 463px 130px 0px rgba(0, 0, 0, 0);

    .modal-body {
      padding: 0px;
    }
    .modal-close-btn {
      position: absolute;
      top: 54px;
      right: 37px;
      cursor: pointer;
      z-index: 1056;
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 22px;
    padding: 78px;
    .TickWrapper {
      width: 140px;
      height: 140px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .modal-text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 41px;
        letter-spacing: -1.28px;
      }
      .modal-body-text {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
        letter-spacing: 0.72px;
      }
      .modal-body-caption {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.64px;
      }
      .progressbar-caption {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.64px;
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}
