.__floater__body{
  direction: ltr;
}

.react-joyride__tooltip {
  color: #f2f4f7 !important;
  font-family: "Karla", sans-serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  // z-index: 5;
  button[aria-label="Next"] {
    display: flex;
    padding: 8px 16px !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px !important;
    background: var(--optimistic-orange-600-main);
    cursor: pointer;
  }
  button[aria-label="Back"] {
    display: flex;
    padding: 8px 16px !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px !important;
    //   background: var(--optimistic-orange-600-main);
    cursor: pointer;
    border: 1px solid var(--optimistic-orange-600-main) !important;
    color: var(--white);
  }
  button[aria-label="Close"] {
    background-color: #007bff;
    border: 1px solid white !important;
    border-radius: 100% !important;
    padding: 2px !important;
    overflow: hidden;
    color: white;
    top: 23px !important;
    right: 23px !important;
    cursor: pointer;
  }

  button[aria-label="Last"] {
    background-color: #ff690f;
    border: 1px solid #ff690f !important;
    border-radius: 25px !important;
    padding: 8px !important;
    overflow: hidden;
    color: rgb(255, 255, 255) !important;
    top: 23px !important;
    right: 23px !important;
    cursor: pointer;
    width: 65px;
    font-family: karla;
    
  }
}
.react-joyride__tooltip {
  padding: 16px 12px !important;
}
div.react-joyride__tooltip > div > div {
  padding: 0px !important;
}
.joyride_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #4d4f5c;
  padding: 0px 10px;
  padding-bottom: 12px;
  .form-label {
    color: #98a2b3;
    font-family: "Karla", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0px;
  }
  .dropdown-toggle {
    display: flex;
    font-family: "Karla", sans-serif;
    height: 40px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .dropdown-menu {
    border-radius: 8px;
    background: #363638;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    max-height: 264px;
    overflow-y: auto;
    .dropdown-item {
      padding: 10px 16px;
      color: var(--white);
      /* Text/sm/regular */
      font-family: "Karla", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &:hover {
        background-color: transparent;
        opacity: 0.7;
      }
    }
  }
}
.joyride_body {
  padding: 12px 10px 0px;
  margin-bottom: 24px;
  color: #f2f4f7;
  /* Text/sm/regular */
  font-family: "karla";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
.tooltip_counting {
  position: absolute;
  bottom: 23px;
  left: 23px;
}
