@media screen and (max-width:750px){

    .desktop-sidebar{
        display: none;
    }
}

    .desktop-sidebar{
        position: fixed;
        z-index: 51;
    }
