.feedbackContentWrapper {
  display: flex;
  color: white;
  height: 100%;
  flex-direction: column;
  .suggetion-box-wrapper {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    .suggetion-box {
      padding: 4px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 38px;
      border: 1px solid #5f6268;
    }
  }
  .form-control-custom-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #2d322d;
    padding: 10px 8px 10px 0px !important;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
    .form-control-custom {
      color: var(--white);
      background-color: transparent;
      border: none;
      &::placeholder {
        color: var(--grey-main-600);
      }
    }
  }
}
.ButtonInput {
  //   position: absolute;
  width: fit-content !important;
  //   top: 2px;
  //   right: 8px;
  padding: 12px !important;
  background-color: #2d322d;
  border: none;
  &.active {
    background-color: var(--optimistic-orange-600-main) !important;
    border-color: var(--optimistic-orange-600-main) !important;
  }
}
