.data-results-container {
  .alert {
    padding: 10px;
    border-radius: 12px;
    border: 1px solid var(--bright-teal-600-main);
    background: #e7f8f0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    font-family: Karla;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .alert-success {
    .alert-icon {
      background-color: var(--bright-teal-600-main);
      border-radius: 100%;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
