.feedbackModalWrapper {
  position: relative !important;
  display: flex !important;
  z-index: 70 !important;
  padding: 0px 16px 16px 16px !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  border-radius: 16px !important;
  border: 1px solid var(--grey-main-600) !important;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05) !important;
  background-color: var(--charcoal-100) !important;
  max-width: 350px !important;
  height: 445px !important;
  position: fixed !important;
  bottom: 100px !important;

  .feedbackHeader {
    padding: 16px !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    color: var(--gray-500) !important;
  }
  .feedbackBody {
    display: flex !important;
    width: 100% !important;
    height: 100% !important;
    flex-direction: column !important;
    .nav-tabs {
      display: flex !important;
      width: 100% !important;
      max-width: 100% !important;
      gap: 12px !important;
      border-radius: 12px !important;
      background: #2d322d !important;
      padding: 6px !important;
      border: none !important;
      .nav-item {
        flex: 1 !important;
        .nav-link {
          display: flex !important;
          width: 100% !important;
          padding: 10px 16px !important;
          justify-content: center !important;
          align-items: center !important;
          border-radius: 8px !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: 20px !important;
          letter-spacing: -0.56px !important;
          color: var(--white) !important;
          &:active {
          }
        }
        .nav-link.active {
          background-color: var(--charcoal-100) !important;
        }
      }
    }
    .tab-content {
      height: 100% !important;
      .tab-pane {
        height: 100% !important;
      }
    }
  }
}
