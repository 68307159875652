.plan-container-parent{
    display: flex;
    flex-direction: row;
    gap:20px;
    padding: 0px 3%;
    justify-content: center;
    width:max-content;
    height: max-content;
    /* overflow-y: hidden; */
    margin-bottom: 30px;
    max-width:100%;
}

.grand-parent-plan-container{
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plan-container-child{
  display: flex;
  flex-direction: row;
  gap:15px;
  height: 580px;
}

.plan-description{
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -1px;
}

.payment-plan-features-container {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  gap:12px
}

.plan-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: space-between; */
    height: 548px;
    width: 335px !important;
    min-width: 335px !important;
    background-color: white;
    border-radius: 15px;;
    margin: 20px auto 0px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow */
}


.select-plan-heading-container{
    /* overflow: hidden; */
  display: flex;
  justify-content: flex-start;
  /* margin-top: 50px; */
}


.child-wrapper {
  width: 335px; /* Width of each child wrapper */
  height: 571px;
  display: inline-block;
  min-width: 335px !important;
}

@media screen and (max-width:900px) {

  /* .plan-container-parent{
    overflow-y: hidden;
  } */
  
  .select-plan-heading-container{
    display: none;
    margin-bottom: 50px;
  } 
}

/* @media screen and (max-width:600px) {
 


} */


@media screen and (max-width:600px) {

  .amount-per-month{
    padding: 0px 5px !important;
  }
  
}

@media screen and (min-width:1130px) {
  .grand-parent-plan-container{
    overflow:hidden;
  }
}

.select-plan-heading{
  font-size: 46px;
  letter-spacing: -1px;
  font-weight: 700;
}

@media screen and (max-width:1000px) {
  .grand-parent-plan-container{
    width: 100%;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media  screen and (max-height:740px) {

  .plan-container-parent{
    height: fit-content !important;
  }

  
}



@media screen and (max-width:1830px) {
    .plan-container{
        width: 335px;
        min-width: 335px;
        height: 100%;
        margin: 10px auto;
        display: inline-block;
        padding: 10px;
        white-space:pre-wrap;
        align-items: center;
    }    

    .plan-description{
      font-size: 17.4px;
    }

    .plan-container-parent{
        /* width: 100vh; */
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%; /* Fixed width of the container */
        overflow-x: auto; /* Enable horizontal scrolling */
        white-space: nowrap; /* Prevent child divs from wrapping */
        height: 100%;
        justify-content: flex-start;
        padding: 20px 50px 25px 50px;
        /* overflow-y: hidden; */
    }

    .child-wrapper {
        width: 335px; /* Width of each child wrapper */
        height: 560px;
        min-width: 335px;
        display: inline-block;
      }
    
      .suggested-plan-placement-en{
        transform: translate(30px, -27px) !important;
      }

      .suggested-plan-placement-ar{
        transform: translate(-17px, -25px) !important;
      }

      
}

.plan-price-container{
    display: flex;
    /* justify-content: space-between; */
    font-size: large;
    font-weight: bold;
    padding:10px 30px 0px 30px;
  }

  .plan-price-container h2{
    font-weight: bold;
    letter-spacing: -2px;
  }

  .plan-type-container-dimensions{
    height: 160px;
  }

  .plan-price-container-dimensions{
    height: 80px;
  }

  .plan-features-container-dimensions{
    height: 180px;
    padding-top:20px
  }

  @media screen and (max-width:600px) {

    .plan-features-container-dimensions{
      padding-top:10px;
    }

    .grey-note-div{
      padding-top: 0px !important;
    }
    
  } 

  .button-height-ar{
    height: 52px !important;
    font-size: 15px !important;
}

  .div-row{
    display: flex;
    flex-direction: row;
  }

  .amount-per-month{
    margin: 8px;
    font-weight: 500;
  }
  .grey-note-div{
    padding-top: 10px;
    min-height: 36px !important;
    /* border: 2px solid red; */
  }

  .grey-note-div p{
    font-size: 12px;
  }

  .button-container-custom{
    padding: 20px 30px
  }

  .black-button-style{
    color:rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);

    letter-spacing: -1px;
    display: inline-block;
    height: 50px;
    line-height: 15px;
    padding: 15px 20px;
    border: 1px solid #000000;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    font-weight: bold;
    font-size: 18px;

    margin-top: 10px;
  }

  .black-button-style:hover{
    color: black;
    background-color: white;
  }

  .suggested-plan{
    border-radius: 30px;
    display: inline-block;
    padding: 3.5px 20px;
    border: 2px solid black;
    background-color: black;
    color: white;
    position: sticky;
    font-size: medium;
    height: 35px;
    width: fit-content;
  }

  .suggested-plan-placement-en{
    transform: translate(30px, -17px);    
  }

  .suggested-plan-placement-ar{
    transform: translate(-17px, -15px);
  }

  .total-amount-note-container{
    height: 20px;
  }

  .feature-p{
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -1px;
  }

  
  .chip-label-heading{
    padding-top: 0px !important; 
  }

  .chip-label-plan-type-container-height{
    height: 120px !important
  }

  .chip-label-current-plan{
      border: 2px solid #fe6100 !important;
      background-color: #fe6100 !important;
      color: white;

      border-radius: 30px;
      display: inline-block;
      padding: 3.5px 20px;
      position: sticky;
      font-size: medium;
      height: 35px;
      width: fit-content;
  }

  .orange-current-plan-padding{
    padding-top: 70px !important;
  }