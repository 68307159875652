.invoice-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 132px);
  overflow-y: auto;
  // border: 2px solid red;
  font-family: "Karla", sans-serif;
  // height: 100%;

   // fixes icon from becoming enlarged due to wider width
   @media screen and (min-width: 900px) {

    svg {

      height: 80px !important; 

      path,
      circle {
        stroke: var(--grey-main);
      }
    }    
  }

    @media screen and (max-width: 750px) {
      .invoice-container{
        padding-top: 153px !important;
      }
    }

  .invoice-container {
    height: 100%;
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 540px;
    gap: 20px;


    
    &-top {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    span {
      letter-spacing: -0.64px;
    }

    .upload-wrapper {
      min-height: 228px;
      position: relative;
      border-radius: 20px;
      border: 2px dashed var(--gray-500);
      background: var(--white);
      padding: 45px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 12px;
      overflow: hidden;


 

            // fixes upload cloud svg icon from becoming enlarged due to small width
            @media screen and (max-width: 900px) {

              svg {
      
                height: 80px; 
      
                path,
                circle {
                  stroke: var(--grey-main);
                }
              }
        
              
            }

      svg {

        path,
        circle {
          stroke: var(--grey-main);
        }
      }

 

      &-text {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        max-width: 200px;
        letter-spacing: -0.56px;
        &-browser {
          color: var(--soft-yellow-800);
        }
      }
      .file-Input {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }

    .upload-wrapper:hover{
      
      &:hover{
        background-color: var(--bg-dark-yellow);
        border-color: var(--soft-yellow);
      }
    }
    
    .Upload-file-wrapper {
      
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 0px;
      padding: 0px;

      .list {
        list-style-type: none;
        background-color: var(--white);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 4px;
        gap: 8px;

        &-inner-box {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #ebeff2;
          flex: 1;
          .progress {
            margin: 8px 0px;
            height: 6px;
          }
          &-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
          }
          .img-name-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
          }
          .file-icon {
            width: 17px;
            height: 40px;
            background-position: center;
            background-repeat: no-repeat;
          }
          .pdf-file {
            background-image: url("../../assets/SVG/PdfIcon.svg");
          }
          .excel-file {
            background-image: url("../../assets/SVG/ExcelIcon.svg");
          }
        }
        .upload-common {
          width: 15px;
          height: 15px;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 100%;
        }
        .uploaded {
          background-color: var(--mint-600-main);
          background-image: url("../../assets/SVG/true.svg");
          border: 4px solid var(--mint-600-main);
        }
        .cross-upload {
          background-color: var(--error-red-600-main);
          border: 4px solid var(--error-red-600-main);
          background-image: url("../../assets/SVG/false.svg");
        }
      }
    }
  }
}

.orange-bg-btn-transition {
  transition: all 0.3s ease;

  &:hover {
    background-color: #fe6100;
    color: white;

    .orange-bg-btn-text {
      color: white;
    }
  }
}