.imported-wrapper {
  background-color: var(--white);
  border-radius: 6px;
  .data-table {
    border-radius: 4px;
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0px;
    //   overflow: hidden;
    th {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      white-space: nowrap;
    }
    thead,
    tbody {
      tr {
        td,
        th {
          padding: 12px 16px;
        }
        td {
          .table-form-control {
            padding: 0px !important;
            border: none;
            border-radius: 0px;
            background-color: transparent;
            transition: all 0.2s;
            font-size: 14px;
            min-width: 80px;
          }
          &:hover {
            background-color: var(--gray-100);
          }
        }
      }
    }
    td {
      padding: 12px 16px;
    }
    .sticky-column {
      position: sticky;
      left: -1px;
    }
    .sticky-column-right {
      position: sticky;
      right: -1px;
    }
    .sticky-column-2 {
      right: 47px;
      &:last-child {
        right: -1px;
      }
    }
  }

  table td,
  table th {
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    // white-space: nowrap;
    width: 200px;
  }

  table td:first-child {
    border-left: none;
  }

  .table-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    padding: 20px;
    position: sticky;
    bottom: 0px;
    background-color: var(--white);
    border-top: 1px solid var(--gray-200);
    .table-footer-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
    }
  }
}
.title-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  padding: 10px 8px;
  gap: 15px;
  .back-btn {
    cursor: pointer;
  }
  &-text {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.28px;
  }
}

.data-results-container {
  padding-left: 0px;
  margin-left: 0%;
}

@media screen and (min-width: 1500px){

  .data-results-container {
    padding-left: 0px;
  }

}


.adding_row {
  opacity: 0.6;
  height: 16px;
  width: 16px;
}

.line-item-modal {
  .modal-header {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--gray-200);
    .modal-title {
      font-family: Karla;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 126.667% */
      letter-spacing: -1.2px;
    }
  }
  .modal-body {
    padding: 24px;
    .table {
      th,
      td {
        border-color: #eee;
        padding: 12px 16px;
        color: var(--gray-900, #171e29);

        /* Paragraph/Regular-md */
        font-family: Karla;
        font-size: 16px;
        font-style: normal;

        line-height: 24px; /* 150% */
        letter-spacing: -0.64px;
        .form-control {
          border: none;
        }
      }
      th {
        font-weight: 500;
      }
      td {
        font-weight: 400;
        padding: 0px;
      }
      .sticky-column-right {
        width: 50px !important;
        padding: 12px 16px;
      }
    }
  }
}
