.InputWrapper {
  // max-width: 692px;
  // width: 100%;
  // position: fixed;
  // bottom: 0px;
  // width: 100%;
  // max-width: 692px;
  display: flex;
  justify-content: center;
  left: 0px;
  position: relative;

  // min-width: 692px;
  border-radius: 12px !important;
  margin-bottom: 42px;
  background: var(--black-white-white, #fff);
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 65px;

  .Input {
    border: none !important;
    // width: 80%;
    position: relative;
    padding: 25px 0px 16px 16px !important;
    height: 76px !important;
    box-shadow: none;
    border-radius: 12px !important;
    color: #74787f !important;
    resize: none;
    &::placeholder {
      position: absolute;
      top: 50%;
      // width: 100%;
      transform: translateY(-50%);
      color: #999; /* Adjust the color as needed */
    }
    &:focus::placeholder {
      color: transparent;
    }
    &:focus {
      padding: 25px !important;
      height: 76px !important;
      border-radius: 12px !important;
      border: none !important;
      background: var(--black-white-white, #fff) !important;
      box-shadow: none !important;
    }
  }
  .ButtonInput {
    position: absolute;
    width: fit-content !important;
    top: 14px;
    right: 16px;
    padding: 10px !important;
    background-color: #dbe0eb;
    border-color: #dbe0eb;
    
    &.active {
      background-color: var(--optimistic-orange-600-main) !important;
      border-color: var(--optimistic-orange-600-main) !important;
    }
  }
  .AttachmentBtn {
    position: absolute;
    width: fit-content !important;
    top: 14px;
    right: 72px;
    padding: 10px 12px !important;
  }
  .StopGeneratingBtn {
    position: absolute;
    width: fit-content !important;
    top: 16px;
    right: 16px;
    padding: 10px 12px !important;
    border: none;
    background-color: var(--white);
    color: #c8352f;
  }

  .StopGeneratingBtnAr {
    position: absolute;
    width: fit-content !important;
    top: 16px;
    left: 16px;
    padding: 10px 12px !important;
    border: none;
    background-color: var(--white);
    color: #c8352f;
  }
}
