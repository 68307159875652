.AnalyzeData-wrapper {
  display: flex;
  flex-direction: column;

  background-color: var(--chalk-soft);
  border-radius: 28px;
  border: 1px solid #e0dccb;
  width: 100%;
  height: 100%;
  height: calc(100vh - 200px);
  overflow: hidden;
  max-width: 1200px;
  margin: auto;
  border-radius: 15px;
  // margin-bottom: 100px;

  //   overflow: hidden;

  .header {
    display: flex;
    padding: 20px;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid var(--gray-200);
    background: var(--white);
    position: sticky;
    top: 0px;
    z-index: 2;

  }

  .footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--gray-200);
    background: var(--white);
    padding: 20px;
    button {
      width: auto;
    }
  }
  .AnalyzeDataResult {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
    // padding-bottom: 150px;
    flex-direction: column;
    gap: 18px;
    height: 100%;
    padding-top: 110px;
    .AnalyzeResultInner {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      // text-align: center;
      width: 100%;
      gap: 18px;
      height: 100%;
      overflow-y: auto;
      .chatLiveContent,
      .ChatAiContainer {
        padding-bottom: 0px !important;
      }
      .ChatScreenWrapper.mb-4 {
        margin-bottom: 0px !important;
        border-radius: 15px;
        border: 1px solid #78cd32;
      }
    }
    .dataNotFound {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 27px;
      max-width: 405px;
      margin: auto;
      img {
        max-width: 237px;
        max-height: 237px;
      }
      p {
        color: var(--gray-700);
        text-align: center;
        font-family: Karla;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 130% */
        letter-spacing: -0.4px;
      }
    }
    .table-wrapper {
      display: flex;
      flex-direction: column;
      gap: 18px;
      width: 100%;
      margin-bottom: 16px;
      .heading-table {
        color: var(--gray-gray-hover);
        font-family: Karla;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.04px;
        text-align: left;
      }
    }
    .GraphBox {
      z-index: 0;
      display: flex;
      justify-content: center;
      background-color: var(--white);
      border-radius: 8px;
      border: 1px solid var(--gray-200);
      overflow: hidden;
      // .js-plotly-plot {
      //   width: 100%;
      //   .svg-container {
      //     width: 100% !important;
      //     .main-svg {
      //       width: 100%;
      //     }
      //   }
      // }
    }
  }

  .ChatBox {
    width: 100%;
    // max-width: 500px;
    .header {
      display: flex;
      border-bottom: 1px solid var(--gray-200);
      background: var(--white);
      padding: 20px;
      align-items: center;
      justify-content: space-between;

      p {
        font-family: Karla;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24.5px;
        color: var(--gray-gray-hover);
        padding: 13px 0px 14px 0px;
        margin-bottom: 0px;
      }
      svg {
        height: 20px;
        width: 20px;
        path {
          stroke: var(--gray-gray-hover);
        }
      }
    }
    .chatLiveContent {
      // padding-top: 0px;
      // padding-bottom: 0px;
      height: auto;
      padding-bottom: 0px;
    }
    .ChatAiContainer {
      position: relative;
      // padding-top: 0px;
      // padding-bottom: 0px;
    }
    // .chatInputWrapper {
    //   margin-bottom: 0px;
    // }
    .ChatAiContainerInner {
      max-width: 100%;
    }
  }
}

.orangeBgBtnTransition {
  transition: all 0.3s ease;

  &:hover {
    background-color: #fe6100;  
    color: white;

    .orangeBgBtnText {
      color: white;
    }
  }
}

@media screen and (max-width:750px) {
  .AnalyzeData-wrapper {
    display: flex;
    flex-direction: column;
  
    background-color: var(--chalk-soft);
    border-radius: 28px;
    border: 1px solid #e0dccb;
    width: 100%;
    height: 100%;
    height: calc(100vh - 200px);
    overflow: hidden;
    max-width: 99vw;
    margin: auto;
    // margin-bottom: 100px;
  
    //   overflow: hidden;
  
  
  
      .header {
        display: flex;
        margin-top: 10px;
        align-items: center;
        gap: 8px;
        border-bottom: 1px solid var(--gray-200);
        background: var(--white);
        position: sticky;
        top: 0px;
        z-index: 2;
        overflow-x: auto;
        overflow-y: hidden;
        min-height: 80px;

      }    
    
    .footer {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid var(--gray-200);
      background: var(--white);
      padding: 20px;
      button {
        width: auto;
      }
    }
    .AnalyzeDataResult {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      position: relative;
      // padding-bottom: 150px;
      flex-direction: column;
      gap: 18px;
      height: 100%;
      padding-top: 110px;
      .AnalyzeResultInner {
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        text-align: center;
        width: 100%;
        gap: 18px;
        height: 100%;
        overflow-y: auto;
        .chatLiveContent,
        .ChatAiContainer {
          padding-bottom: 0px !important;
        }
        .ChatScreenWrapper.mb-4 {
          margin-bottom: 0px !important;
        }
      }
      .dataNotFound {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 27px;
        max-width: 405px;
        margin: auto;
        img {
          max-width: 237px;
          max-height: 237px;
        }
        p {
          color: var(--gray-700);
          text-align: center;
          font-family: Karla;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 130% */
          letter-spacing: -0.4px;
        }
      }
      .table-wrapper {
        display: flex;
        flex-direction: column;
        gap: 18px;
        width: 100%;
        margin-bottom: 16px;
        .heading-table {
          color: var(--gray-gray-hover);
          font-family: Karla;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -1.04px;
          text-align: left;
        }
      }
      .GraphBox {
        z-index: 0;
        display: flex;
        justify-content: center;
        background-color: var(--white);
        border-radius: 8px;
        border: 1px solid var(--gray-200);
        overflow: hidden;
        // .js-plotly-plot {
        //   width: 100%;
        //   .svg-container {
        //     width: 100% !important;
        //     .main-svg {
        //       width: 100%;
        //     }
        //   }
        // }
      }
    }
  
    .ChatBox {
      width: 100%;
      // max-width: 500px;
      .header {
        display: flex;
        border-bottom: 1px solid var(--gray-200);
        background: var(--white);
        padding: 20px;
        align-items: center;
        justify-content: space-between;
  
        p {
          font-family: Karla;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24.5px;
          color: var(--gray-gray-hover);
          padding: 13px 0px 14px 0px;
          margin-bottom: 0px;
        }
        svg {
          height: 20px;
          width: 20px;
          path {
            stroke: var(--gray-gray-hover);
          }
        }
      }
      .chatLiveContent {
        // padding-top: 0px;
        // padding-bottom: 0px;
        height: auto;
        padding-bottom: 0px;
      }
      .ChatAiContainer {
        position: relative;
        // padding-top: 0px;
        // padding-bottom: 0px;
      }
      // .chatInputWrapper {
      //   margin-bottom: 0px;
      // }
      .ChatAiContainerInner {
        max-width: 100%;
      }
    }
  }
}
.chat-input-analyze {
  width: 100%;
  display: flex;
  justify-content: center;
  // position: fixed;
  // bottom: 83px;
  .chatInputWrapper {
    position: relative;
    left: 0px;
    margin-bottom: 35px;
  }
}
