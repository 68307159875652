.starter-plan-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    height: 730px; 
    width: 523px;
    background-color: white;
    border-radius: 15px;;
    margin: 15px auto 0px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow */
}

@media screen and  (max-width:500px){
  .total-amount-note{
    font-size: 14px !important;
  }
}

@media screen and (max-width:600px) {
    .starter-plan-container{
        width:90%;
        height: fit-content;
        margin: auto;
    }    
    .total-amount-container{
      padding:20px 30px 15px 30px !important;
    }

    .grey-line-custom{
      margin-top:20px !important;
    }

    .starter-plan-heading{
      font-size: 27px !important;
    }

    .currency-select{
      width: 130px !important;
      outline: "none" !important;
    }

    .plan-features-container{
      gap:2.5px !important;
    }
}

.plan-type-container{
    padding: 0 30px;
}

.starter-plan-heading{
    font-weight: bold;
    padding: 30px 0px 0px 0px;
    letter-spacing: -2px;
    font-size: 32px;
}

  

.button-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
    padding: 0px 30px;
}



.custom-button {
    letter-spacing: -1px;
    display: inline-block;
    height: 50px;
    line-height: 15px;
    padding: 15px 20px;
    border: 2px solid #fe6100;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    background-color: #fe6100;
  }

  .custom-button-back {
    letter-spacing: -1px;
    margin-top: 10px;
    display: inline-block;
    height: 50px;
    line-height: 15px;
    padding: 15px 20px;
    border: 1px solid black;
    border-radius: 10px;
    color: black;
    cursor: pointer;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    border-color:#141E14;
  }
  
  .custom-button:hover {
    background-color: white;
    color: #fe6100;
  }

  .module-use-info-div{
    display: flex;
  }

  .plan-features-container{
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    /* gap:5.5px; */
  }

  .grey-line-custom{
    /* padding-left: 20px;
    padding-right: 20px; */
    margin: 5px auto;
    width: 90%;
  }

  .total-amount-container{
    display: flex;
    justify-content: space-between;
    font-size: large;
    font-weight: bold;
    padding:15px 30px 15px 30px;
  }

  .total-amount-container h2{
    font-weight: bold;
    letter-spacing: -2px;
  }



  .save-amount-chip{
    border-radius: 30px;
    display: inline-block;
    flex-direction: column;
    padding: 0px 10px;
    border: 2px solid #fe6100;
    background-color: #fe6100;
    color: white;
    font-size: 18px;
    height: 30px;

  }

  .grey-note{
    font-size: small;
    color:grey;
    padding-left: 35px;
  }

  .grey-note-ar{
    font-size: small;
    color:grey;
    padding-right: 35px;
  }

  .total-amount-note-container{
    height: 20px;
    margin-top:5px
  }

  .total-amount-note{
    font-size: 18px;
    color: black;
    font-weight: 400;
    text-align: end;
    margin-top:-10px;
    letter-spacing: -1px;
  }

  .column-div{
    display: flex;
    flex-direction: column;
  }

  .payment-term-container{
    padding: 25px 30px;

  }

  .payment-term-heading{
    font-size: 15px;
    color: #626962;
    font-style: italic;
    font-weight: 700;
  }

  .payment-term-body{
    color:#626962;
    margin-top:-1px;
    font-size: 15px;
    line-height: 16px;  
  }


  .plan-navbar-container{
    justify-content: flex-start;
  }

  .plan-navbar-ar{
    margin-right: 3%;
  }

  @media screen and (max-width:900px){
    .plan-navbar-container{
      justify-content: space-between;
    }
  } 

  .black-hover-effect{
    margin-top: 0px;
    color: white;
    background-color: black;
    transition: all 0.3s ease;
  }

  .black-hover-effect:hover{
    color:black;
    background-color: white;
  }

  .terms-policy-container{
    display:flex;
    justify-content: space-between;
    padding: 15px 30px;
  }

  .terms-policy-label{
    font-weight: 700;
    letter-spacing: -1px;
    font-size: 14px;
    cursor: pointer;
  }

  .currency-select{
    width: 150px;
    height: 44px !important;
    margin-top: 35px !important;
  }

  .heading-currency-select-container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .flags-ar{
    position: absolute;
    right: 10px;
  }

  .force-bold-link{
    font-weight: bold !important;
  }

  .navbar-labels{
    letter-spacing: -1px !important;
    font-size: 18px !important;
    /* font-weight: 700; */
  }

  .feature-p{
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -1px;
  }
