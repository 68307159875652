.profile-info-pic {
  color: white;
}

.ModalBodywrapper {
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100vh - 285px);
  overflow-y: auto;
  // padding-bottom: 0px;

  .pictureContainer {
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-items: center;
    margin-bottom: 16px;

    .img-edit-wrapper {
      width: 91px;
      height: 88px;
      position: relative;

      .edit-img {
        display: flex;
        width: 32px;
        height: 32px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 9px;
        flex-shrink: 0;
        border-radius: 31px;
        border: 3px solid var(--chalk);
        background: #fff;
        position: absolute;
        top: 0px;
        right: 0px;
      }

      .img-wrapper {
        width: 83px;
        height: 83px;
        overflow: hidden;
        border-radius: 100%;
        strokeWidth: 1px;
        stroke: var(--gray-500);

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .name-user {
        color: #000;
        text-align: center;
        /* Heading/H4-sm */
        font-family: Karla;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        /* 133.333% */
        letter-spacing: -0.72px;
      }

      .mail-user {
        color: var(--gray-800);
        text-align: center;
        /* Paragraph/Medium-lg */
        font-family: Karla;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
      }
    }
  }
}