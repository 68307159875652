.TabBox {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  padding-bottom: 12px;
  .nav-btn {
    border-radius: 48px;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    z-index: 20;
    &.tooltip {
      border-bottom: none;
    }
  }
  .upload {
    z-index: 19;
  }
  .tabler {
    z-index: 18;
  }
  .vediobtn {
    z-index: 9;
  }
  .chatbtn {
    z-index: 8;
  }
  .tourbtn {
    z-index: 7;
    .tooltiptext {
      left: 0px;
      // margin-left: -29px;
      padding: 8px;
    }
  }
}
