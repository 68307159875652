.data-results-container {
  .dropdown-wrapper {
    .dropdown-custom {
      padding: 14px 24px !important;
      justify-content: center;
      align-items: center;
      border-radius: 28px;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      font-family: karla;
      background-color: transparent;
      border: 1px solid var(--optimistic-orange-600-main) !important;
    }
    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      background-color: transparent;
      box-shadow: none;
    }
    .download-dropdown {
      background-color: var(--optimistic-orange-600-main) !important;
      color: var(--white) !important;
    }
    .submit-dropdown {
      border: 1px solid var(--optimistic-orange-600-main) !important;
      color: var(--black) !important;
    }
    .dropdown-menu.show {
      display: block;
      width: auto;
      background: var(--white);
      padding: 10px;
      border-radius: 8px;
      border: none !important;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    }
    .dropdown-item {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      font-family: Karla;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.64px;
      color: var(--gray-900) !important;
    }
  }
}
