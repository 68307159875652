.historyModal {
  //   background-color: red;
    max-width: 412px;
    width: 370px;
    padding: 24px;
    height: 100vh;
    overflow-y: auto;
    position: relative;
  
  .overlay-history {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: var(--black);
    z-index: 5;
    background-color: #0000004f;
    z-index: 1;
    transition: opacity 0.15s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner-border {
      position: relative;
      top: 0px;
      left: 80%;
    }
  }
  .historyModal-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: var(--gray-900);
      font-family: Karla;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 122.222% */
    }
  }
  .nav-tabs {
    border-radius: 12px;
    overflow: hidden;
    padding: 8px;
    background-color: var(--white) !important;
    margin: 0px;
    .nav-item {
      flex: 1;
      margin-bottom: 0px;
      &:not(:last-child) {
        margin-right: 12px;
      }
      .nav-link {
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        width: 100%;
        color: var(--black);
        font-family: Karla;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.56px;
        white-space: nowrap;
        border: none;
        // flex: 1 0 0;
      }
    }
  }
}

@media screen and (min-width:750px) {
  

  .historyModal-ar{
    right: 110px;
    position: fixed; /* Use fixed positioning */
    bottom: 0;       /* Cover the bottom of the viewport */
    width: 370px;    /* Width of the sidebar */
    max-width: 412px;
    padding: 24px;
    height: 100vh;   /* Full height of the viewport */
    overflow-y: auto; /* Allow vertical scrolling */
    z-index: 100;   /* Ensure it is on top of other elements */
    background: inherit; /* Set the background color */
  }

  .historyModal-en{
    left: 110px;
    position: fixed; /* Use fixed positioning */
    bottom: 0;       /* Cover the bottom of the viewport */
    width: 370px;    /* Width of the sidebar */
    max-width: 412px;
    padding: 24px;
    height: 100vh;   /* Full height of the viewport */
    overflow-y: auto; /* Allow vertical scrolling */
    z-index: 100;   /* Ensure it is on top of other elements */
    background: inherit; /* Set the background color */
  }
}


@media screen and (max-width:750px) {

  .historyModal {

    //have history modal appear on top of chatbotmodule on mobile screen
    background-color: inherit; 
    position: fixed !important;
    max-width: 100% !important;
    z-index: 200;
    top: 60px;
    width: 100% !important;
    left: 0 !important;
    padding: 24px;
    height: 100vh;
    overflow-y: auto;
    position: relative;
    .overlay-history {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      background-color: var(--black);
      z-index: 5;
      background-color: #0000004f;
      z-index: 1;
      transition: opacity 0.15s linear;
      display: flex;
      justify-content: center;
      align-items: center;
      .spinner-border {
        position: relative;
        top: 0px;
        left: 80%;
      }
    }
    .historyModal-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: var(--gray-900);
        font-family: Karla;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 122.222% */
      }
    }
    .nav-tabs {
      border-radius: 12px;
      overflow: hidden;
      padding: 8px;
      background-color: var(--white) !important;
      margin: 0px;
      .nav-item {
        flex: 1;
        margin-bottom: 0px;
        &:not(:last-child) {
          margin-right: 12px;
        }
        .nav-link {
          display: flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          width: 100%;
          color: var(--black);
          font-family: Karla;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          letter-spacing: -0.56px;
          white-space: nowrap;
          border: none;
          // flex: 1 0 0;
        }
      }
    }
  }
  
}
.form-control-icon-wrapper {
  position: relative;
  .form-control-custom {
    padding: 8px 35px 8px 12px !important;
    border-radius: 8px;
  }
  &::after {
    content: "";
    position: absolute;
    // background-color: red;
    width: 22px;
    height: 22px;
    top: 10px;
    right: 12px;
    background-image: url("../../assets/SVG/search.svg");
    background-repeat: no-repeat;
  }
}
.closeHistory {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}